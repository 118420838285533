<template>
  <div class="statusItemV3" @click.stop="pushDetail">
    <div class="header">
      <div class="title" :style="{ width: titleWidth }">
        {{ getValue() }}
      </div>

      <van-rate v-if="showStart" color="#ffd21e"
                void-icon="star"
                void-color="#eee" readonly :size="14" class="rate" v-model="dataInfo.levelNum"
      />


      <slot name="headHandle"/>
    </div>

    <div class="content">
      <sub-item
          @clickAction="pushDetail"
          v-for="item in keyList"
          :key="item.key"
          :bracket="false"
          :spacing="item.space"
          :color="item.color"
          :item-info="getKeyValue(item)"
          :width="$getDataValue(item, 'width', '50%')"
          :align="'left'"
      ></sub-item>

      <sub-item
          @clickAction="pushDetail"
          v-for="item in rowKeyList"
          :key="item.key"
          :bracket="false"
          :spacing="item.space"
          :item-info="getKeyValue(item)"
          :width="'100%'"
      ></sub-item>


    </div>
    <div v-if="showCancelShare" class="actionView">
      <div class="statusDetail">
        <div style="position: relative;top: 50%;transform: translate(0px, -50%)" v-html="status"></div>
      </div>
    </div>
    <div v-if="showCancelShare" class="actionView">
      <slot name="footHandle"/>
    </div>
    <div v-if="showAction" class="actionView">
      <div class="agree" @click.stop="agreeAction">同意</div>
      <div class="disagree" @click.stop="disagreeAction">拒绝</div>
    </div>
    <div v-if="showShare" class="actionView">
      <div class="agree" @click.stop="shareAction">设置共享</div>
    </div>
  </div>
</template>

<script>

import SubItem from '@/views/shareAndDispatch/device/deviceList/subItem'
import { formatOrderStatus } from '@/assets/JS/utils.js'
import DialogView from '@/components/dialogView/dialogView'

export default {
  name: 'statusItemV3',
  components: { DialogView, SubItem },
  computed: {
    status() {
      if (this.$valueIsExist(this.dataInfo, 'orderStatus')) {
        return formatOrderStatus(this.dataInfo['orderStatus'])
      }

      if (this.$valueIsExist(this.dataInfo, 'status')) {
        console.log('status = ' + this.dataInfo['status'])
        return formatOrderStatus(this.dataInfo['status'])
      }

      return ''
    },

    titleWidth() {
      let width = this.titleWidthSpace
      if (this.showCancelShare) {
        width = 100 + width
      }
      let str = this.$changePxToRem(width + 'px')
      return `calc(100% - ${str})`
    }
  },
  props: {
    // 是否显示评价
    showStart:{
      default:true,
    },
    titleWidthSpace() {
      defaults:90
    },
    keyList: {
      type: Array,
      default: () => {
        return []
      }
    },
    rowKeyList: {
      type: Array,
      default: () => {
        return [{ key: 'brandName', name: '品 牌', space: '21px', float: 'right' },
          { key: 'modelName', name: '型 号', space: '21px', float: 'right' },
          { key: 'label', name: this.$VUEAPPDEVICECODE(), space: '0px', float: 'right' },
          { key: 'firstShareTime', name: '首次共享', space: '0px', float: 'right' },
          { key: 'code', name: '卡 片 号', space: '3px', float: 'right' },
          { key: 'status', name: '共享状态', space: '3px', float: 'right' },
          { key: 'categoryName', name: '设备分类', space: '0px', float: 'right' },
          { key: 'loanCount', name: '共享次数', space: '21px', float: 'right' }]
      }
    },
    headerTitelKye: {
      type: String,
      default: 'deviceName'
    },
    showCancelShare: {
      type: Boolean,
      default: false
    },
    showShare: {
      type: Boolean,
      default: false
    },
    showAction: {
      type: Boolean,
      default: false
    },
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    shareAction: function() {
      this.$emit('shareAction', this.dataInfo)
    },
    cancelShareAction: function() {
      this.$emit('cancelShareAction', this.dataInfo)
    },
    disagreeAction: function() {
      this.$emit('disagree', this.dataInfo)
    },
    agreeAction: function() {
      this.$emit('agree', this.dataInfo)
    },
    pushDetail: function() {
      this.$emit('pushDetail', this.dataInfo)
    },
    getValue: function() {
      if (this.$valueIsExist(this.dataInfo, this.headerTitelKye)) {
        return this.dataInfo[this.headerTitelKye]
      }
      return ''
    },
    getKeyValue: function(item) {
      let getDepreciationMethod = (depreciationMethod) => {
        if (depreciationMethod == '1') {
          return '年限平均法'
        } else if (depreciationMethod == '2') {
          return '工作量法'
        } else if (depreciationMethod == '3') {
          return '双倍余额递减法'
        } else if (depreciationMethod == '4') {
          return '年数总和法'
        }
        return ''
      }

      let data = {
        title: item.name,
        value: '',
        showDetail: false,
        detailValue: '',
        float: 'left'
      }

      if (this.$valueIsExist(item, 'float')) {
        data['float'] = item['float']
      }

      if (this.$valueIsExist(this.dataInfo, item.key)) {
        let _value = this.dataInfo[item.key]
        if (_value != '' || _value != undefined || _value != null) {
          data.value = _value
        } else {
          data.value = ''
        }
        if (item.key == 'firstShareTime') {
          data.value = this.formatTimeHHMM(data.value)
          data.showDetail = true
          data.detailValue = this.formatDate(_value)
        } else if (item.key == 'orderTime') {
          data.value = this.formatDate(data.value)
          data.showDetail = false
          data.detailValue = this.formatDate(_value)
        } else if (item.key == 'orderStatus' || item.key == 'status') {
          let str = ''
          str = formatOrderStatus(data.value)
          data.value = str
        } else if (item.key == 'yearLimit') {
          data.value = data.value == '' ? '-' : data.value + '年'
        } else if (item.key == 'depreciationMethod') {
          data.value = getDepreciationMethod(data.value);
        }
      }

      return data
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.statusItemV3 {
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0px 0px 5px $color4;
  background-image: linear-gradient(to bottom, rgb(211, 223, 254, 0.9), rgba(211, 223, 254, 0.5), rgba(211, 223, 254, 0.3), white);


  .header {
    width: 100%;
    min-height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .rate {
      width: 95px;
    }

    .title {
      width: calc(100% - 110px);
      font-weight: 500;
      margin-left: 8px;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }


  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 4px;
  }


  .actionView {
    width: 100%;
    height: 30px;
    margin-top: 4px;
    margin-bottom: 10px;

    .statusDetail {
      height: 100%;
      float: left;
      margin-left: 10px;
    }

    .action {
      float: right;
      border-radius: 15px;
      margin-right: 10px;
      padding: 0 12px;
      height: 30px;
      text-align: center;
      color: $color_primary;
      line-height: 30px;
      background-color: white;
      border: 1px solid $color_primary;
    }


    .agree {
      width: 70px;
      margin-right: 10px;
      float: right;
      border-radius: 15px;
      margin-right: 10px;
      height: 30px;
      text-align: center;
      color: $color_primary;
      line-height: 30px;
      background-color: white;
      border: 1px solid $color_primary;

    }

    .disagree {
      border-radius: 5px;
      float: right;
      border-radius: 15px;
      margin-right: 10px;
      width: 70px;
      height: 30px;
      text-align: center;
      color: red;
      line-height: 30px;
      background-color: white;
      border: 1px solid red;
    }
  }
}

</style>
