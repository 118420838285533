<template>
  <van-popup v-model="show" position="bottom" round>
    <overflowYHidden>
      <div class="borrowPopup">
        <div class="content">
          <div class="title titleBottom">选择</div>
          <div class="main">
            <div class="title">借用设备</div>
            <div class="button buttonActive">{{ detailsData.name }}</div>
          </div>
          <div class="main">
            <div class="title">借用时间</div>
            <dateTimeRange v-model="baseData.date" :format="formatDate" :datetimeToolbar="true" :title="'选择时间'" :minIsNewDate="true" :formatter="formatter" type="datetime" :placeholder="['开始日期', '结束日期']"></dateTimeRange>
          </div>
          <!-- <div class="main">
            <div class="title">借用时长</div>
            <div class="timePick">
              <div
                class="button"
                :class="{ buttonActive: nowTimeType == 1 }"
                @click="showTimeCollapse(1)"
              >
                1小时
              </div>
              <div
                class="button"
                :class="{ buttonActive: nowTimeType == 2 }"
                @click="showTimeCollapse(2)"
              >
                3小时
              </div>
              <div
                class="button"
                :class="{ buttonActive: nowTimeType == 3 }"
                @click="showTimeCollapse(3)"
              >
                {{ customValue > 0 ? customValue + "小时" : "自定义" }}
              </div>
            </div>
            <div class="datetime" :class="{ timeCollapse: timeCollapse }">
              <div class="spaceBetween">
                <div></div>
                <van-stepper v-model="customValue" :min="0" :max="48" />
              </div>
            </div>
          </div> -->
        </div>
        <div class="footer" @click="submit">确定</div>
      </div>
    </overflowYHidden>
  </van-popup>
</template>

<script>
import overflowYHidden from "@/components/overflowYHidden";
import dateTimeRange from '@components/dateTimeRange';
export default {
  components: { overflowYHidden,dateTimeRange },
  props: {
    detailsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      show: false,
      nowDataType: 1,
      nowTimeType: 1,
      timeCollapse: false,
      customValue: 0,
      baseData: {
        hour: "",
        date:''
      },
    };
  },
  created() {
    
  },
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      } else if (type === "hour") {
        return `${val}时`;
      } else if (type === "minute") {
        return `${val}分`;
      }
    },
    showPopuo() {
      this.show = true;
    },
    showTimeCollapse(type) {
      if (type == 3) {
        this.timeCollapse = !this.timeCollapse;
      }
      this.nowTimeType = type;
    },
    submit() {
      let _hour = "";
      switch (this.nowTimeType) {
        case 1:
          _hour = 1;
          break;
        case 2:
          _hour = 3;
          break;
        case 3:
          _hour = this.customValue;
          break;
        default:
          break;
      }
      this.$emit("borrowSubmit", {
        startTime: this.baseData.date[0],
        endTime: this.baseData.date[1],
        hour: _hour,
      });
      this.show = false;
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.borrowPopup {
  padding: 22px 24px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: $color1;
    padding-bottom: 12px;
  }
  .titleBottom {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.09);
  }
  .main {
    padding-top: 22px;
    .button {
      display: inline-block;
      padding: 0 24px;
      height: 32px;
      line-height: 32px;
      background: #f5f5f5;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 400;
      color: $color1;
      border: 1px solid #f5f5f5;
    }
    .buttonActive {
      background: #d8e3ff;
      border: 1px solid #3e73fb;
      color: #3e73fb;
    }
    .timeButton {
      padding: 0;
      width: 142px;
      text-align: center;
      font-size: 12px;
    }
    .userTime {
      display: flex;
      justify-content: space-between;
      .solid {
        width: 30px;
        height: 2px;
        background: #666666;
        margin-top: 15px;
      }
    }
    .datetime {
      height: 0px;
      overflow: hidden;
      transition: all 0.3s linear;
    }
    .spaceBetween {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
    }
    .timeCollapse {
      height: 42px;
      transition: all 0.3s linear;
    }
    .timePick {
      display: flex;
      justify-content: space-between;
    }
  }
  .content {
    // min-height: 430px;
  }
  .footer {
    margin-top: 36px;
    width: 100%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #3e73fb;
    border-radius: 18px;
    color: #ffffff;
    font-size: 16px;
  }
}
::v-deep.van-popup {
  overflow: hidden;
}
</style>