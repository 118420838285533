<template>
  <div class="deviceBorrowItem">
    <overflowYHidden>
      <van-pull-refresh
          v-model="loading"
          class="workWait"
          id="workWait"
          @refresh="searchEvent"
      >
        <div class="deviceContent" ref="deviceContent" v-show="list.length > 0">
          <statusItemV3
              :show-start="showStart"
              v-for="item in list"
              :key="item.orderId"
              :dataInfo="item"
              :showCancelShare="true"
              :headerTitelKye="headerTitelKye"
              :rowKeyList="rowKeyList"
              :keyList="keyList"
              @pushDetail="toDeviceDetails(item)"
          >
            <template v-slot:footHandle>
              <div v-if="item.orderStatus == 1">
                <van-button style="width: 70px" class="action" @click.stop="toDeviceRepair(item)"
                >报障
                </van-button
                >
                <van-button class="action" @click.stop="checkCode(item)">
                  编码校验
                </van-button>
                <van-button class="action" @click.stop="doSomeThing(item)">
                  扫码使用
                </van-button>
                <van-button class="action" @click.stop="cancelOrder(item)">
                  取消预约
                </van-button>
              </div>
              <van-button
                  class="action"
                  v-if="item.orderStatus == 3 || item.orderStatus == 5 || item.orderStatus == 7 || item.orderStatus == 8"
                  @click.stop="checkClean(item)"
              >
                归还
              </van-button>
              <van-button
                  class="action"
                  v-if="item.orderStatus == 3 || item.orderStatus == 5 || item.orderStatus == 7 || item.orderStatus == 8"
                  @click.stop="lateTime(item)"
              >
                延迟时长
              </van-button>
              <van-button
                  v-if="item.orderStatus == 0"
                  class="action"
                  @click.stop="shareCancelBooking(item)"
              >
                取消预约
              </van-button>
              <van-button
                  v-if="item.orderStatus == -2"
                  class="action"
                  @click.stop="showBorrowPopup(item)"
              >
                重新预约
              </van-button>
              <van-button
                  class="action"
                  v-if="item.orderStatus == 4||viewType=='repair'"
                  @click.stop="toRepairedOrder(item)"
              >
                报修工单
              </van-button>
            </template>
          </statusItemV3>
          <div class="loadMore" v-show="loadMore">
            <i class="el-icon-loading"></i>更多加载中...
          </div>
          <div
              class="loadMore"
              v-show="totalCount != 0 && totalCount == list.length"
          >
            没有更多数据了
          </div>
        </div>
        <div v-show="list.length == 0" class="emptyView">
          <KcEmpty description="暂无数据"></KcEmpty>
        </div>
      </van-pull-refresh>
    </overflowYHidden>
    <borrowPopup
        ref="borrowPopup"
        :detailsData="detailsData"
        @borrowSubmit="borrowSubmit"
    ></borrowPopup>

    <dialog-in-put @dialogClose="dialogClose"
                   :content="inputCode"
                   :message="'请输入设备编码/卡片号'"
                   :match-type="'.'"
                   :dialog-visible="showInPut"
    >
    </dialog-in-put>
  </div>
</template>

<script>
import overflowYHidden from '@/components/overflowYHidden'
import statusItemV3 from '@/views/shareAndDispatch/device/deviceShareDetail/deviceStatus/statusItemV3'
import borrowPopup from '@/views/shareAndDispatch/deviceBorrow/deviceMall/popup/borrowPopup.vue'
import deviceBorrowMixin from '@views/shareAndDispatch/deviceBorrow/deviceBorrowMixin'
import DialogInPut from '@/components/inPut/dialogInPut'
import MessageBox from '@components/messageBox/messageBox'
import HStack from '@components/hStack/hStack'
import VStack from '@components/vStack/vStack'

export default {
  mixins: [deviceBorrowMixin],
  computed: {
    viewType() {
      let query = this.$route.query
      if (this.$valueIsExist(query, 'viewType')) {
        return query['viewType']
      }
      return ''
    }
  },
  props: {
    headerTitelKye: {
      default: 'name'
    },
    // 是否显示评价
    showStart: {
      default: true
    },
    loaddingClass: {
      type: String,
      default: ''
    },
    rowKeyList: {
      type: Array,
      default: () => {
        return []
      }
    },
    keyList: {
      type: Array,
      default: () => {
        return []
      }
    },
    queryType: {
      type: Number,
      default: 0
    }
  },
  components: {
    statusItemV3,
    overflowYHidden,
    borrowPopup,
    DialogInPut
  },
  data() {
    return {
      inputCode: '',
      showInPut: false,
      selectItem: null,
      list: [],
      page: 1,
      pageSize: 30,
      totalCount: 0,
      loadMore: false,
      loading: false,
      scrollbarEl: '',
      detailsData: {}
    }
  },
  created() {
  },
  mounted() {
    this.searchEvent()
    this.$nextTick(() => {
      // 配置微信jssdk
      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.wxConfig(corpInfo)
        } catch (e) {
        }
      }
      this.scrollbarEl = this.$refs.deviceContent
      this.scrollbarEl.onscroll = () => {
        this.scrollEvent()
      }
    })
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.searchEvent)
  },
  methods: {
    checkCode: function(item) {
      this.showInPut = true
      this.selectItem = item
    },
    dialogClose: function(res) {
      if (this.selectItem == null || res.result == false) {
        this.inputCode = ''
        this.showInPut = false
        this.selectItem = null
        return
      }
      let res1 = typeof this.selectItem['label'] != 'undefined' && this.selectItem['label'] != null
      let res2 = typeof this.selectItem['code'] != 'undefined' && this.selectItem['code'] != null

      if (!res1 && !res2) {
        this.$toast.fail(`${this.selectItem['name']}没录入${this.$VUEAPPDEVICECODE()}/卡片号`)
        this.inputCode = ''
        this.showInPut = false
        this.selectItem = null
        return
      }

      let label = res1 ? this.selectItem['label'] : ''

      let code = res2 ? this.selectItem['code'] : ''

      let text = res.input

      if (label.indexOf(text) < 0 && code.indexOf(text) < 0) {
        this.$toast.fail(`${text}与${this.selectItem['name']}${this.$VUEAPPDEVICECODE()}/卡片号不匹配`)
        this.inputCode = ''
        this.showInPut = false
        this.selectItem = null
        return
      }
      this.showInPut = false
      setTimeout(() => {
        this.$push('deviceBorrow/submitScanning', {
          orderId: this.selectItem.orderId
        })
        this.selectItem = null
        this.inputCode = ''
      }, 500)
    },
    scrollEvent() {
      let scrollTop = this.scrollbarEl.scrollTop
      let offsetHeight = this.scrollbarEl.offsetHeight
      let scrollHeight = this.scrollbarEl.scrollHeight
      if (
          scrollTop + offsetHeight == scrollHeight &&
          this.totalCount > this.list.length
      ) {
        this.page += 1
        this.loadMore = true
        this.deviceOrderList()
      }
    },
    searchEvent() {
      this.page = 1
      this.loadMore = true
      this.deviceOrderList()
    },
    deviceOrderList() {
      let getOrderList = () => {
        this.$showLoading({
          target: '.' + this.loaddingClass
        })
        this.$api.deviceBorrowModule
            .deviceOrderList({
              queryType: this.queryType,
              efficiency: 1,
              page: this.page,
              pageSize: this.pageSize
            })
            .then((res) => {
              this.loadMore = false
              this.loading = false
              this.totalCount = res.totalCount
              if (this.page == 1) {
                this.list = res.data
              } else {
                this.list.push(...res.data)
              }
              this.$hideLoading({
                target: '.' + this.loaddingClass
              })
            })
            .catch((res) => {
              this.loading = false
              this.loadMore = false
              this.$hideLoading({
                target: '.' + this.loaddingClass
              })
            })
      }

      let getRepairList = () => {
        this.$showLoading({
          target: '.' + this.loaddingClass
        })
        this.$api.deviceBorrowModule
            .deviceRepairList({
              queryType: 1,
              page: this.page,
              pageSize: this.pageSize
            })
            .then((res) => {
              this.loadMore = false
              this.loading = false
              this.totalCount = res.totalCount
              for (let i = 0; i < res.data.length; i++) {
                let data = res.data[i]
                data['status'] = -99
              }
              if (this.page == 1) {
                this.list = res.data
              } else {
                this.list.push(...res.data)
              }
              this.$hideLoading({
                target: '.' + this.loaddingClass
              })
            })
            .catch((res) => {
              this.loading = false
              this.loadMore = false
              this.$hideLoading({
                target: '.' + this.loaddingClass
              })
            })
      }
      console.log('~~~')
      if (this.viewType == 'repair') {
        getRepairList()
        return
      }

      getOrderList()

    },
    showBorrowPopup(item) {
      this.detailsData = item
      this.$refs.borrowPopup.showPopuo()
    },
    borrowSubmit(res) {
      this.$dialog.confirm({
        message: `${this.detailsData.name}[预约申请]`,
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            this.$api.deviceBorrowModule
                .shareBook({
                  deviceId: this.detailsData.id,
                  startTime: res.startTime,
                  endTime: res.endTime
                })
                .then((res) => {
                  this.$toast.success('设备预约成功')
                  this.searchEvent()
                  localStorage.setItem('allDeviceUpdate', new Date().getTime())
                  done()
                })
                .catch((e) => {
                  done()
                  this.$toast.fail(e.data.msg)
                })
          } else {
            done()
          }
        }
      })
    },
    lateTime: function(item) {
      this.$push('deviceBorrow/deviceBorrowExtend', {
        id: item.id,
        orderId: item.orderId
      })
    },
    checkClean(item) {
      this.$api.deviceBorrowModule
          .checkClean({
            id: item.orderId
          })
          .then((res) => {
            if (res.data == 1) {
              this.toDetails(item)
            } else {
              this.toGiveBackDetails(item)
            }
          })
          .catch((e) => {
          })
    },
    toRepairedOrder(item) {
      let query = {}
      if (this.viewType == 'repair') {
        query = {
          id: item.id
        }
      } else {
        query = {
          id: item.orderId,
          serialNumber: item.serialNumber,
          categoryName: item.categoryName,
          reportTime: item.reportTime,
          status: item.status
        }
      }
      this.$push('deviceRepair/repairedOrder', query)
    },
    toDetails(item) {
      this.$push('deviceBorrow/disinfectDetails', {
        orderId: item.orderId
      })
    },
    toGiveBackDetails(item) {
      this.$push('deviceBorrow/giveBackDetails', {
        id: item.orderId,
        orderTime: item.orderTime
      })
    },
    toDeviceRepair(item) {
      this.$push('deviceBorrow/deviceRepair', {
        id: item.orderId,
        address: item.address,
        modelName: item.modelName,
        code: item.code,
        name: item.name
      })
    },
    cancelOrder: function(item) {
      this.$dialog.confirm({
        message: `是否取消的预约?`,
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            this.$api.deviceBorrowModule
                .shareCancelBooking({
                  id: item.orderId
                })
                .then((res) => {
                  this.$toast.success('操作成功')
                  localStorage.setItem('allDeviceUpdate', new Date().getTime())
                  this.searchEvent()
                  done()
                })
                .catch((e) => {
                  this.$toast.fail(e.data.msg)
                  done()
                })
          } else {
            done()
          }
        }
      })
    },
    doSomeThing: function(item) {
      // this.$push('deviceBorrow/submitScanning', {
      //   orderId: item.orderId
      // })
      this.scanQRCode().then((res) => {
        if (res.result == false) {
          this.$toast.fail('请扫描二维码或者条形码')
          return
        }
        this.startUsing(res, item)
      })
    },
    startUsing(res, item) {
      // if (item.code != res.res) {
      //   this.$dialog
      //       .confirm({
      //         title: '提示',
      //         message: `系统检测到帐号非该设备预约人,请正确扫描预约设备`
      //       })
      //       .then(() => {
      //       })
      //       .catch(() => {
      //       })
      // } else {
      //   this.$push('deviceBorrow/submitScanning', {
      //     orderId: item.orderId
      //   })
      // }

      setTimeout(() => {
        this.$push('deviceBorrow/submitScanning', {
          orderId: item.orderId
        })
      }, 500)
    },
    shareCancelBooking(item) {
      this.$dialog
          .confirm({
            message: `确认取消预约${item.name}?`
          })
          .then(() => {
            this.$api.deviceBorrowModule
                .shareCancelBooking({
                  id: item.orderId
                })
                .then((res) => {
                  this.$toast.success('操作成功')
                  localStorage.setItem('allDeviceUpdate', new Date().getTime())
                  this.searchEvent()
                })
                .catch((e) => {
                  this.$toast.fail(e.data.msg)
                })
          })
          .catch(() => {
          })
    },
    toDeviceDetails(item) {
      let query = {}
      if (this.viewType == 'repair') {
        return
      } else {
        query['id'] = item.id
        query['orderId'] = item.orderId
      }
      this.$push('deviceBorrow/deviceDetails', query)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceBorrowItem {
  padding: 10px 10px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  .emptyView {
    height: 360px;
  }

  .loadMore {
    text-align: center;
    color: $color4;
    font-size: 12px;
    padding: 12px;
  }
}

.loadMore {
  text-align: center;
  color: $color4;
  font-size: 12px;
  padding: 12px;
}

</style>